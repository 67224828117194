import React, { useState } from "react";
import { useSuspenseQuery, useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../../app/slices/globalUISlice";
import BookCover from "../helpers/BookCover";
import api from "../../utils/api";
import Layout from "../layout/Layout";
import HeadTags from "../helpers/HeadTags";
import { withFallback } from "vike-react-query";
import ViewMoreButton from "../helpers/ViewMoreButton";
import FeedCard from "../cards/FeedCard";
import LoginPrompt from "../helpers/LoginPrompt";
import Tag from "../helpers/Tag";
import { getMaterialIcon } from "../../utils/icons";
const InfoIcon = getMaterialIcon("Info");

const Discuss = () => {
  const currentUserId = useSelector((state) => state.user.currentUserId);
  const dispatch = useDispatch();
  const [feedType, setFeedType] = useState(currentUserId ? "yourBooks" : "allBooks");

  const { data: trendingBooks, isLoading } = useSuspenseQuery({
    queryKey: ["mostActive"],
    queryFn: () => api.get(`/books?q=trending`),
  });

  const { data: readalongs } = useSuspenseQuery({
    queryKey: ["readalongs"],
    queryFn: () => api.get(`/books/readalongs`),
  });

  const yourBooksPostsQuery = useSuspenseInfiniteQuery({
    queryKey: ["yourBooksPosts", currentUserId],
    queryFn: ({ pageParam }) => api.get(`/book_post_feed?q=userBooks&page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => (pages.length < lastPage.total_pages ? pages.length + 1 : undefined),
    enabled: !!currentUserId,
  });
  const yourBooksPosts = yourBooksPostsQuery.data.pages.flatMap((page) => page.book_posts);

  const allBooksPostsQuery = useSuspenseInfiniteQuery({
    queryKey: ["allBooksPosts"],
    queryFn: ({ pageParam }) => api.get(`/book_post_feed?q=allBooks&page=${pageParam}`),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => (pages.length < lastPage.total_pages ? pages.length + 1 : undefined),
  });
  const allBooksPosts = allBooksPostsQuery.data.pages.flatMap((page) => page.book_posts);

  return (
    <div className="relative h-max min-h-[100vh] mx-auto m-auto bg-cover bg-no-repeat bg-discuss-squiggle">
      <HeadTags
        title="Discuss"
        description="Join the conversation and share your thoughts on books, authors, and more."
      />
      <Layout title="Discuss">
        <div className="relative flex flex-col gap-8 md:gap-10 w-full px-4 md:w-9/12 max-w-[750px] m-auto justify-center">
          <div className="flex flex-col gap-4 self-start md:self-center">
            <h3 className="font-bold">Most Currently Reading</h3>
            <div className="flex flex-row w-full gap-2 md:gap-4 pb-2 pr-2">
              {trendingBooks.slice(0, 5).map((book) => (
                <div key={`${book.id}-popular`} className="w-[62px] h-24 md:w-[104px] md:h-40 flex-none">
                  <BookCover book={book} />
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-4 self-start md:self-center">
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-2">
                <h3 className="font-bold">Fall Readalongs</h3>
                <button
                  type="button"
                  onClick={() =>
                    dispatch(
                      openModal({
                        name: "info",
                        data: {
                          title: "Introducing: Seasonal Readalongs! 📚",
                          body: "With so many books, it can be hard to know where the conversation is happening. We're featuring 5 books for fall, so if you're looking to chat, this is a great place to start!<br/><br/>Add a featured book to your TBR or mark it as currently reading to participate in the Readalong. Already finished one of the books? Now's a great time to share your thoughts in the forum.<br/><br/>The Fall Readalong will run through the end of November; winter selections will drop mid-November.",
                        },
                      })
                    )
                  }
                >
                  <InfoIcon className="text-fuschia" fontSize="medium" />
                </button>
              </div>
              <p className="mb-2">Read one (or more!) alongside the community and join the convo 🍁👻🎃</p>
            </div>

            <div className="flex flex-row gap-2 w-full md:gap-4 pb-2 pr-2 overflow-x-scroll customScrollbar">
              {readalongs.map((book) => (
                <div key={`${book.id}-readalong`} className="flex flex-col gap-2 items-center justify-center">
                  <div className="w-[62px] h-24 md:w-[104px] md:h-40 flex-none">
                    <BookCover book={book} />
                  </div>
                  <Tag text={`${book.readalong_count} joined`} color="bg-pastelPink border-1 border-pink font-bold" />
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-4 items-center">
            <div className="flex flex-row gap-16 md:gap-24">
              <button
                key="yourBooks"
                className={`text-base font-bold ${
                  feedType === "yourBooks" ? "border-b-8 border-mainGreen" : "text-gray"
                }`}
                onClick={() => setFeedType("yourBooks")}
              >
                My Books
              </button>
              <button
                key="allBooks"
                className={`text-base font-bold ${
                  feedType === "allBooks" ? "border-b-8 border-mainGreen" : "text-gray"
                }`}
                onClick={() => setFeedType("allBooks")}
              >
                All Books
              </button>
            </div>

            {feedType === "yourBooks" && (
              <React.Fragment>
                {currentUserId ? (
                  <React.Fragment>
                    {yourBooksPosts.length > 0 ? (
                      <div className="flex flex-col gap-4">
                        {yourBooksPosts.map((post) => (
                          <FeedCard key={post.id} activityItemData={post} />
                        ))}
                        <ViewMoreButton {...yourBooksPostsQuery} showCaughtUp key={feedType} />
                      </div>
                    ) : (
                      <p className="text-center">
                        No posts yet for your books!
                        <br />
                        Start a discussion by posting in a forum, or check out posts from other books.
                      </p>
                    )}
                  </React.Fragment>
                ) : (
                  <LoginPrompt />
                )}
              </React.Fragment>
            )}

            {feedType === "allBooks" && (
              <div className="flex flex-col gap-4">
                {allBooksPosts.map((post) => (
                  <FeedCard key={post.id} activityItemData={post} />
                ))}
                <ViewMoreButton {...allBooksPostsQuery} showCaughtUp key={feedType} />
              </div>
            )}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default withFallback(Discuss);
